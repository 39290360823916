<template src="./NewCV.html"></template>

<script>
import jobsService from "@/services/Jobs";
import coursesService from "@/services/Courses";
import locationService from "@/services/Locations";
import UploadImageModal from "@/components/UploadImageModal/UploadImageModal";
import StatesByCountry from "@/components/StatesByCountry/StatesByCountry";
import CitiesByState from "@/components/CitiesByState/CitiesByState";
import { mapGetters } from "vuex";

export default {
  name: "NewCV",
  components: {
    UploadImageModal,
    StatesByCountry,
    CitiesByState,
  },
  data() {
    return {
      form: {
        firstStage: {
          personalInformation: {
            name: "",
            firstSurname: "",
            secondSurname: "",
            cellPhone: "",
            mail: "",
            pageWebPersonal: "",
            residenceDepartment: "",
            cityResidence: "",
          },
          socialNetworks: [],
          education: [],
          workingInformation: {
            workArea: "",
            specialization: "",
            aboutMe: "",
          },
          skills: [],
          languages: [],
          workExperience: [],
          references: [],
        },
        secondStage: {
          additionalPersonalData: {
            basicData: {
              typeDocument: "",
              documentNumber: "",
              sex: "",
              nationality: {
                nationality: "",
                country: "",
              },
            },
            militaryCard: {
              class: "",
              number: "",
              DM: "",
            },
            dateAndPlaceOfBirth: {
              date: null,
              country: "",
              department: "",
              municipality: "",
            },
            location: {
              correspondenceAddress: "",
              country: "",
              department: "",
              municipality: "",
            },
          },
          additionalEducation: {
            basicAndHalfEducation: {
              basicEducation: 0,
              obtainedTitle: "",
              degreeDate: null,
            },
            higherEducation: [
              {
                academicModality: "",
                numberOfSemestersPassed: "",
                graduate: false,
                titleName: "",
                termination: null,
                professionalCardNumber: "",
              },
            ],
            idiom: [
              {
                name: "",
                speech: "",
                writing: "",
                reading: "",
              },
              {
                name: "",
                speech: "",
                writing: "",
                reading: "",
              },
            ]
          },
          additionalWorkingInformation: {
            currentEmployemtOrCurrentConstract: {
              business: "",
              publicOrPrivate: "",
              country: "",
              department: "",
              municipality: "",
              emailBusiness: "",
              phoneNumberBusiness: "",
              dateInit: null,
              dateEnd: null,
              position: "",
              dependence: "",
              address: "",
            },
            previusEmployemntOrContract: [
              {
                business: "",
                publicOrPrivate: "",
                country: "",
                department: "",
                municipality: "",
                emailBusiness: "",
                phoneNumberBusiness: "",
                dateInit: null,
                dateEnd: null,
                position: "",
                dependence: "",
                address: "",
              },
            ],
          },
          totalExperienceTime: {
            publicServer: {
              years: "",
              months: "",
            },
            privateSectorEmployee: {
              years: "",
              months: "",
            },
            independentWorker: {
              years: "",
              months: "",
            },
            totalTimeExperience: {
              years: 0,
              months: 0,
            },
          },
          signatureOfThePublicServantOrContractor: {
            iStateUnderTheGravityOfTheOauthThatYes: false,
            date: null,
          },
        },
      },
      name: "",
      step: 1,
      curriculumTemplate: "@Medellin",
      modalUploadImageIsActive: false,
      infoFile: {},
      imageLink: "",
      countries: [],
    };
  },
  mounted() {
    if (this.$route.query.curriculumId) {
      this.getCurriculum(this.$route.query.curriculumId);
    } else {
      this.form.secondStage.signatureOfThePublicServantOrContractor.date = new Date();
    }
    this.getCountries();
  },
  methods: {
    back() {
      if (this.step == 1) {
        this.$router.push({ name: 'Job' })
      } else {
        this.step = 1
      }
    },
    step2() {
      if (this.form.firstStage.skills.length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta almenos 1 habilidad",
          position: "is-bottom",
          type: "is-warning",
        });
        return null;
      }
      if (this.form.firstStage.workExperience.length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta almenos 1 experiencia laboral",
          position: "is-bottom",
          type: "is-warning",
        });
        return null;
      }
      if (this.form.firstStage.education.length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta almenos 1 educación",
          position: "is-bottom",
          type: "is-warning",
        });
        return null;
      }
      if (this.form.firstStage.languages.length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta almenos 1 lenguaje",
          position: "is-bottom",
          type: "is-warning",
        });
        return null;
      }
      if (this.form.firstStage.references.length === 0) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Hace falta almenos 1 referencia",
          position: "is-bottom",
          type: "is-warning",
        });
        return null;
      }
      this.step = 2;
    },
    async getCurriculum(curriculumId) {
      const response = await jobsService.getCurriculum(curriculumId);
      this.curriculumTemplate = response.curriculumTemplate;
      this.imageLink = response.fingerprint;
      const form = response.form;
      for (let index = 0; index < form.firstStage.education.length; index++) {
        form.firstStage.education[index].dateInit = new Date(form.firstStage.education[index].dateInit);
        form.firstStage.education[index].dateFinish = new Date(form.firstStage.education[index].dateFinish);
      }
      for (let index = 0; index < form.firstStage.workExperience.length; index++) {
        if (form.firstStage.workExperience[index].startDate) {
          form.firstStage.workExperience[index].startDate = new Date(form.firstStage.workExperience[index].startDate);
        }
        if (form.firstStage.workExperience[index].endDate) {
          form.firstStage.workExperience[index].endDate = new Date(form.firstStage.workExperience[index].endDate);
        }
      }
      form.secondStage.additionalPersonalData.dateAndPlaceOfBirth.date = new Date(form.secondStage.additionalPersonalData.dateAndPlaceOfBirth.date);
      form.secondStage.additionalEducation.basicAndHalfEducation.degreeDate = new Date(form.secondStage.additionalEducation.basicAndHalfEducation.degreeDate);
      for (let index = 0; index < form.secondStage.additionalEducation.higherEducation.length; index++) {
        form.secondStage.additionalEducation.higherEducation[index].termination = new Date(form.secondStage.additionalEducation.higherEducation[index].termination);
      }
      form.secondStage.additionalWorkingInformation.currentEmployemtOrCurrentConstract.dateInit = new Date(form.secondStage.additionalWorkingInformation.currentEmployemtOrCurrentConstract.dateInit);
      form.secondStage.additionalWorkingInformation.currentEmployemtOrCurrentConstract.dateEnd = new Date(form.secondStage.additionalWorkingInformation.currentEmployemtOrCurrentConstract.dateEnd);
      for (let index = 0; index < form.secondStage.additionalWorkingInformation.previusEmployemntOrContract.length; index++) {
        form.secondStage.additionalWorkingInformation.previusEmployemntOrContract[index].dateInit = new Date(form.secondStage.additionalWorkingInformation.previusEmployemntOrContract[index].dateInit);
        form.secondStage.additionalWorkingInformation.previusEmployemntOrContract[index].dateEnd = new Date(form.secondStage.additionalWorkingInformation.previusEmployemntOrContract[index].dateEnd);
      }
      this.form = form;
      this.name = response.name;
    },
    addAcademic() {
      if (this.form.secondStage.additionalEducation.higherEducation.length <= 5) {
        this.form.secondStage.additionalEducation.higherEducation.push(
          {
            academicModality: '',
            numberOfSemestersPassed: '',
            graduate: false,
            titleName: '',
            termination: null,
            professionalCardNumber: ''
          })
      }
    },
    onKeyPressRemoveNumbers(event) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
        event.preventDefault();
      }
    },
    createCurriculum() {
      this.form.secondStage.totalExperienceTime.totalTimeExperience = {
        years: parseInt(this.form.secondStage.totalExperienceTime.publicServer.years) + parseInt(this.form.secondStage.totalExperienceTime.privateSectorEmployee.years) + parseInt(this.form.secondStage.totalExperienceTime.independentWorker.years) | 0,
        months: parseInt(this.form.secondStage.totalExperienceTime.publicServer.months) + parseInt(this.form.secondStage.totalExperienceTime.privateSectorEmployee.months) + parseInt(this.form.secondStage.totalExperienceTime.independentWorker.months) | 0,
      }
      const data = {
        curriculumTemplate: this.curriculumTemplate,
        fingerprint: this.imageLink,
        form: this.form,
        name: this.name,
      };
      console.log(data);
      this.$store.dispatch("fetchCv", data);
      if (this.$route.query.curriculumId) {
        if (this.curriculumTemplate === "@Medellin") {
          this.$router.push(
            `/SimpleCV?curriculumId=${this.$route.query.curriculumId}`
          );
        } else if (this.curriculumTemplate === "formatoUnico") {
          this.$router.push(
            `/SimpleCV2?curriculumId=${this.$route.query.curriculumId}`
          );
        }
      } else {
        if (this.curriculumTemplate === "@Medellin") {
          this.$router.push(
            `/SimpleCV`
          );
        } else if (this.curriculumTemplate === "formatoUnico") {
          this.$router.push(
            `/SimpleCV2`
          );
        }
      }
    },
    async updateSignatureCV(file) {
      try {
        await this.$store.dispatch("fetchLoading", true);
        this.infoFile = file;
        this.imageLink = await coursesService.uploadFile(
          "CV-PDF",
          `SignatureFolder${this.user.id}`,
          this.infoFile.file.name,
          this.infoFile.file
        );
        await this.$store.dispatch("fetchLoading", false);
      } catch (e) {
        console.log(e);
      }
    },
    async getCountries() {
      try {
        this.countries = await locationService.getCountries();
      } catch (e) {
        console.error("error", e);
      }
    },
    deleteFromArray(array, index) {
      array.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped lang="scss" src="./NewCV.scss"></style>

<template src="./StatesByCountry.html"></template>

<script>
import locationService from "@/services/Locations";

export default {
  name: "StatesByCountry",
  props: {
    value: [String, Object],
    change: Function,
    country: String,
  },
  model: {
    prop: "value",
    event: "update",
  },
  data: () => ({
    states: [],
  }),
  mounted() {
    this.getStatesChange(this.country);
  },
  methods: {
    async getStatesChange(country) {
      try {
        if (country.length > 0) {
          this.states = await locationService.getStates(country);
        }
      } catch (e) {
        console.error("error", e);
      }
    },
    changeValue(e) {
      if (this.change) this.change(e);
    },
  },
  watch: {
    country(newV, oldV) {
      if (newV !== oldV) {
        this.getStatesChange(newV);
      }
    }
  }
};
</script>

<template src="./CitiesByState.html"></template>

<script>
import locationService from "@/services/Locations";

export default {
  name: "CitiesByState",
  props: {
    value: [String, Object],
    change: Function,
    state: String,
    country: String,
  },
  model: {
    prop: "value",
    event: "update",
  },
  data: () => ({
    cities: [],
  }),
  mounted() {
    if (this.country && this.state) {
      this.getCitiesChange(this.country, this.state);
    }
  },
  methods: {
    async getCitiesChange(country, state) {
      try {
        this.cities = await locationService.getCities(country, state);
      } catch (e) {
        console.error("error", e);
      }
    },
    changeValue(e) {
      if (this.change) this.change(e);
    },
  },
  watch: {
    state(newV, oldV) {
      if (newV !== oldV) {
        this.getCitiesChange(this.country, newV);
      }
    },
    country() {
      this.cities = [];
    },
  },
};
</script>
